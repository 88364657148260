import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

import bigFish from '../src/images/big-fish-white.png'

function Dots() {

    let dots = []
    for (let i = 1; i < 7; i ++) {
        const dotNum = 'dot-' + i
        const dotClass = classNames('dot', dotNum)
        dots.push(
            <div key={dotNum} className={dotClass}></div>
        )
    }
    return dots
}

function App() {

    const cloudUrl = 'https://drive.google.com/file/d/1LtWSFjwUk6xduoIFORyce9h_LeQ6qqvh/view?usp=sharing'

    const [ poke, setPoke ] = useState(false)
    const [ unfold, setUnfold ] = useState(false)
    const [ showJD, setShowJD ] = useState(0)
    const [ canHold, setCanHold ] = useState(false)
    const [ door, setDoor ] = useState('close')

    const pokeTimeoutRef = useRef()
    const surpriseTimeoutRef = useRef()


    useEffect(() => {
        if (poke === true) {
            pokeTimeoutRef.current = setTimeout(()=> {
                setPoke(false)
            }, 150)
        }
    }, [poke])

    useEffect(()=> {
        if (showJD === 7) {
            setTimeout(()=> {
                setCanHold(true)
            }, 4500)
        }
    }, [showJD])

    useEffect(()=> {
        if (door === 'open') {
            setTimeout(()=> {
                setDoor('close')
            }, 1200)
        }
    }, [door])

    function handleClickFish() {
        if (poke === false) {
            setPoke(true)
        }
        if (showJD < 7) {
            setShowJD(showJD + 1)
        }
    }

    function handleOpen() {

        if (showJD === 7 && canHold === true) {

            surpriseTimeoutRef.current = setTimeout(()=> {
                window.open(cloudUrl, '_blank')
            }, 900)

            if (door === 'close') {
                setDoor('open')
            }
        }
    }

    const floatingClass = classNames('floating-fish', {
        'poke': poke
    })
    const moreInfoClass = classNames('more-info', {
        'unfold': unfold
    })
    const companyClass = classNames('company', {
        'show': unfold
    })
    const mainClass = classNames('main', {
        'show-JD': showJD === 7,
        'hold-door': canHold,
        'go-to-JD': door === 'open'
    })
    const blowClass = classNames('blow', 'active-' + showJD)
    const doorClass = classNames('door', {
        'open': door === 'open'
    })

    return (
        <div className={mainClass}>
            <div className={floatingClass} onClick={handleClickFish}>
                <div className="rotate">
                    <div className={blowClass}>
                        <Dots />
                        <img src={bigFish}></img>
                    </div>
                </div>
            </div>
            <div className="greeting">
                <div className="slogan"></div>
                {/* <h1>Fugu Fish Creations</h1> */}
                <h2>we create.</h2>

                <div className="surprise">
                    <div className="door-cover"></div>
                    <div className="door-area">
                        <div className={doorClass} onClick={handleOpen}>
                            <div className="lights">
                                <div className="light light-1"></div>
                                <div className="light light-2"></div>
                                <div className="light light-3"></div>
                                <div className="light light-4"></div>
                                <div className="light light-5"></div>
                            </div>
                            <div className="panel"></div>
                        </div>
                    </div>
                    <div className="tip"></div>
                </div>

                <div className="say-hi">
                    say <a href="mailto:hi@fugu.ooo">hi@fugu.ooo</a>
                </div>

            </div>

            <div className="links">
                <a className="facebook" href="https://www.facebook.com/fugufc" target="_blank"></a>
                <a className="instagram" href="https://www.instagram.com/fugufc/" target="_blank"></a>
                <a className="linkedin" href="https://www.linkedin.com/company/fugufc/" target="_blank"></a>

                <div className={moreInfoClass} onClick={()=> {setUnfold(!unfold)}}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
            </div>

            <div className={companyClass}>
                <div>+886-2-2559-9189</div>
                <div>No. 60, Ln. 202, Hulin St., Xinyi Dist., Taipei, 110031</div>
            </div>
        </div>
    );
}

export default App;
